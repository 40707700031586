import { ImageProps } from 'next/image';
import co_owned_forest from '@/lib/icons/co_owned_forest.svg';
import farm from '@/lib/icons/farm.svg';
import forest from '@/lib/icons/forest.svg';
import home from '@/lib/icons/home.svg';
import leisure_home from '@/lib/icons/leisure_home.svg';
import plot from '@/lib/icons/plot.svg';
import bank from '@/lib/icons/bank.svg';
import checkmark from '@/lib/icons/checkmark.svg';
import document from '@/lib/icons/document.svg';
import euro from '@/lib/icons/euro.svg';
import forest2 from '@/lib/icons/forest2.svg';
import sign_document from '@/lib/icons/sign_document.svg';

export type StaticImport = Exclude<ImageProps['src'], string>;

const icons = {
  co_owned_forest: co_owned_forest as StaticImport,
  farm: farm as StaticImport,
  forest: forest as StaticImport,
  home: home as StaticImport,
  leisure_home: leisure_home as StaticImport,
  plot: plot as StaticImport,
  bank: bank as StaticImport,
  checkmark: checkmark as StaticImport,
  document: document as StaticImport,
  euro: euro as StaticImport,
  forest2: forest2 as StaticImport,
  sign_document: sign_document as StaticImport,
};

export default icons;
